import { API } from 'api';
import { TFunction } from 'i18next';
import { ProcessPaymentState } from 'shared/types';
import { formatToUSDollars } from 'shared/utils';
import Yup from 'shared/yup-extended';

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  optInToUpdates: boolean;
};

export const isEmailAvailable = async (email: string): Promise<boolean> => {
  const response = await API.get<{ hasActiveMembership: boolean }>(
    `/recurly_integration/membership_validation/?email=${encodeURIComponent(
      email
    )}`
  );
  return !response.data.hasActiveMembership;
};

export const isFieldDisabled = (
  isMembershipTransaction: boolean,
  initialState: ProcessPaymentState,
  initialValue: string
): boolean => {
  const { existingUser, updatePaymentMethod } = initialState;

  if (isMembershipTransaction) {
    return false;
  }

  if (!existingUser || !initialValue) {
    return false;
  }

  return !updatePaymentMethod;
};

export const getPaymentText = (
  t: TFunction,
  isMembershipTransaction: boolean,
  isMembershipUpgrade: boolean | undefined,
  transactionAmount: number
): string => {
  if (isMembershipTransaction && !isMembershipUpgrade) {
    return t('recurlyAccountInformation.paymentMethod.purchaseMembership');
  }
  if (isMembershipTransaction && isMembershipUpgrade) {
    return t('recurlyAccountInformation.paymentMethod.upgradeMembership');
  }
  return t('recurlyAccountInformation.paymentMethod.donate', {
    amount: formatToUSDollars(transactionAmount)
  });
};

export const getValidationSchema = (
  t: TFunction,
  existingUser: boolean
): Yup.SchemaOf<FormValues> =>
  Yup.object({
    firstName: Yup.string().required(
      t('recurlyAccountInformation.inputs.firstName.required')
    ),
    lastName: Yup.string().required(
      t('recurlyAccountInformation.inputs.lastName.required')
    ),
    email: Yup.string()
      .required(t('recurlyAccountInformation.inputs.email.required'))
      .test({
        name: 'is-email-available',
        // eslint-disable-next-line func-names, object-shorthand
        test: async function (value) {
          if (!value || existingUser) {
            return true;
          }

          try {
            Yup.string()
              .email(t('recurlyAccountInformation.inputs.email.invalid'))
              .validTLD(t('recurlyAccountInformation.inputs.email.invalid'))
              .validateSync(value);
          } catch (error) {
            const err = error as Yup.ValidationError;
            return this.createError({ message: err.message, type: err.type });
          }

          // Check email availability
          const isAvailable = await isEmailAvailable(value);
          if (!isAvailable) {
            return this.createError({
              message: t('recurlyAccountInformation.registeredEmail'),
              type: 'registered'
            });
          }

          return true;
        }
      }),
    optInToUpdates: Yup.boolean().required(
      t('recurlyAccountInformation.inputs.optInToUpdates.required')
    )
  });
