import { Button, Dialog, IconButton, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import { useAuthState } from 'state';
import { usePoisState } from 'state/usePoisState';
import { getMapDialogCommonStyles } from './utils';

const useStyles = makeStyles<{
  isGeoEventSelected: boolean;
  interactive: boolean;
}>()((theme, { isGeoEventSelected, interactive }) => {
  return {
    ...getMapDialogCommonStyles(theme, {
      isGeoEventSelected,
      interactive,
      minWidth: 362
    }),
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 8,
      pointerEvents: 'auto'
    },
    button: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      pointerEvents: 'auto',
      '& svg.MuiSvgIcon-root': {
        fontSize: 24
      }
    }
  };
});

export const PoiDialog = (): JSX.Element | null => {
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const {
    permissions: { canReport, isInternalUser }
  } = useAuthState();
  const { classes } = useStyles({
    isGeoEventSelected: Boolean(selectedGeoEventId),
    interactive: canReport || isInternalUser
  });
  const {
    selectedPois,
    selectedPoi,
    activeIndex,
    clearSelectedPois,
    selectPreviousPoi,
    selectNextPoi
  } = usePoisState();
  const { t } = useTranslation();

  return (
    <Dialog
      open={!!selectedPois.length}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root,
        container: classes.container,
        paper: classes.paper
      }}
      hideBackdrop
      container={(): HTMLElement | null =>
        document.getElementById('map-container')
      }
    >
      {selectedPoi?.PoiDialogContent && <selectedPoi.PoiDialogContent />}
      {selectedPois.length > 1 && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ mb: 1, mr: 1 }}
        >
          <Button
            variant="text"
            startIcon={<ChevronLeftIcon />}
            className={classes.button}
            onClick={selectPreviousPoi}
            disabled={activeIndex === 0}
          >
            {t('common.prev')}
          </Button>
          <Button
            variant="text"
            endIcon={<ChevronRightIcon />}
            className={classes.button}
            onClick={selectNextPoi}
            disabled={activeIndex === selectedPois.length - 1}
          >
            {t('common.next')}
          </Button>
        </Stack>
      )}
      <IconButton
        size="small"
        className={classes.closeButton}
        onClick={() => clearSelectedPois()}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};
