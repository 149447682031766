import {
  QueryObserverResult,
  RefetchOptions,
  useQuery
} from '@tanstack/react-query';
import { CacheAPI } from 'api';
import { AlertCamera } from 'hooks/useAlertCameras';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  CameraTimeLapseFrameDetails,
  PlayerStatus,
  cameraStatusAtom,
  cameraTimelapseFrameAtom
} from 'state/useAlertCameraPlayerState';

const TIMELAPSE_FRAME_INTERVAL_MS = 200;

const DEFAULT_TIMELAPSE_REPLAY_SEC_PTZ = 60 * 15;
const DEFAULT_TIMELAPSE_REPLAY_SEC_STATIONARY = 60 * 60;

type UseAlertCameraPlayerReturn = {
  playerStatus: PlayerStatus;
  timelapseFrame: CameraTimeLapseFrameDetails | null;
  timelapseFrameNumber: number;
  timelapseFramesLength: number;
  timelapseReplayTime: number;
  isLoading: boolean;
  imageUrl: string;
  imageTimestamp: Date;

  playTimelapse: () => void;
  pauseTimelapse: () => void;
  stopTimelapse: () => void;
  setTimelapseFrameNumber: (frameNumber: number) => void;
  setTimelapseReplayTime: (replayTime: number) => void;

  testOnlyAdvanceTimelapseFrame: () => void;
};

type UseAlertCameraTimelapseFramesReturn = {
  isFetchingTimelapseFrames: boolean;
  timelapseFrames: CameraTimeLapseFrameDetails[];
  refetchTimelapseFrames: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CameraTimeLapseFrameDetails[], Error>>;
};

/*
 * State machine controller for the alert camera player. Takes as input an `AlertCamera`,
 * and outputs the current frame to be displayed, dependent on the player status.
 *
 * Also outputs signals for UI controls:
 * - the player status (playing, paused, live)
 * - if a loading spinner should be displayed
 *
 * As well as state change callbacks:
 * - to change the player status
 * - to explicitly seek to a specific frame in the timelapse
 */
export const useAlertCameraPlayer = (
  camera: AlertCamera,
  testOnlyNoAutoFrameAdvance = false
): UseAlertCameraPlayerReturn => {
  const [playerStatus, setPlayerStatus] =
    useRecoilState<PlayerStatus>(cameraStatusAtom);
  const [timelapseFrameNumber, setTimelapseFrameNumberInternal] = useState(0);
  const [timelapseFrame, setTimelapseFrame] =
    useRecoilState<CameraTimeLapseFrameDetails | null>(
      cameraTimelapseFrameAtom
    );
  const timelapseFrameIntervalRef = useRef<NodeJS.Timeout>();
  const [timelapseReplayTime, setTimelapseReplayTime] = useState(0);

  // Whenever the player status changes to 'streamingLive', clear the timelapse frame so
  // that it does not pollute the viewshed display for any other cameras whose
  // playback is started.
  useEffect(() => {
    if (playerStatus === 'streamingLive') {
      setTimelapseFrame(null);
    }
  }, [playerStatus, setTimelapseFrame]);

  const { timelapseFrames, isFetchingTimelapseFrames, refetchTimelapseFrames } =
    useAlertCameraTimelapseFrames(camera, timelapseReplayTime);

  // Entrypoint for setting the frame number for all functions below. Ensures
  // that the time-lapse frame is always in sync with the frame number.
  const setTimelapseFrameNumber = useCallback(
    (frameNumber: number) => {
      if (frameNumber < 0 || frameNumber >= timelapseFrames.length) return;
      setTimelapseFrameNumberInternal(frameNumber);
      setTimelapseFrame(timelapseFrames[frameNumber]);
    },
    [timelapseFrames, setTimelapseFrame]
  );

  const pauseTimelapse = useCallback((): void => {
    setPlayerStatus('pausedTimelapse');
  }, [setPlayerStatus]);

  const playTimelapse = useCallback(async (): Promise<void> => {
    if (playerStatus === 'streamingLive') {
      // Fetch the newest timelapse frame data and start playing.
      const newFrames = await refetchTimelapseFrames();
      if (!newFrames.data) {
        // If the timelapse frames failed to load, don't start playing.
        return;
      }
      setTimelapseFrameNumberInternal(0);
      setTimelapseFrame(newFrames.data[0]);
    }

    // If the time-lapse has played to the end, reset it.
    if (timelapseFrameNumber === timelapseFrames.length - 1) {
      setTimelapseFrameNumber(0);
    }
    setPlayerStatus('playingTimelapse');
  }, [
    playerStatus,
    refetchTimelapseFrames,
    setPlayerStatus,
    setTimelapseFrame,
    setTimelapseFrameNumber,
    timelapseFrameNumber,
    timelapseFrames.length
  ]);

  const stopTimelapse = useCallback((): void => {
    setTimelapseFrameNumberInternal(0); // Reset the playback slider.
    setPlayerStatus('streamingLive');
  }, [setTimelapseFrameNumberInternal, setPlayerStatus]);

  // When the player is unmounted, stop the player. This prevents a stale timelapse frame
  // from polluting state elsewhere in the app.
  useEffect(() => {
    return () => {
      stopTimelapse();
    };
  }, [stopTimelapse]);

  // When the camera changes, reset the player state.
  useEffect(() => {
    stopTimelapse();
    setTimelapseReplayTime(
      camera.hasPtz
        ? DEFAULT_TIMELAPSE_REPLAY_SEC_PTZ
        : DEFAULT_TIMELAPSE_REPLAY_SEC_STATIONARY
    );
    // NOTE: the `camera` object changes every time cameras are re-fetched, so
    // do not rely on it directly in the dependency array.
  }, [setPlayerStatus, camera.id, camera.hasPtz, stopTimelapse]);

  // When the replay time is changed, stop playback.
  useEffect(() => {
    stopTimelapse();
  }, [timelapseReplayTime, stopTimelapse]);

  const advanceTimelapseFrame = useCallback((): void => {
    if (timelapseFrameNumber >= timelapseFrames.length - 1) {
      pauseTimelapse();
      return;
    }
    setTimelapseFrameNumber(timelapseFrameNumber + 1);
  }, [
    timelapseFrames.length,
    pauseTimelapse,
    setTimelapseFrameNumber,
    timelapseFrameNumber
  ]);

  // Causes the playback timer to fire when the player is in "playing" state.
  // Similarly causes it to stop when the player is in any other state, or reset
  // when any data used within the setInterval() callback changes.
  useEffect(() => {
    if (playerStatus !== 'playingTimelapse') return () => {};
    if (testOnlyNoAutoFrameAdvance) return () => {};

    timelapseFrameIntervalRef.current = setInterval(
      () => advanceTimelapseFrame(),
      TIMELAPSE_FRAME_INTERVAL_MS
    );

    return () => {
      clearInterval(timelapseFrameIntervalRef.current);
      timelapseFrameIntervalRef.current = undefined;
    };
  }, [playerStatus, advanceTimelapseFrame, testOnlyNoAutoFrameAdvance]);

  const { imageUrl, imageTimestamp } = useMemo(() => {
    if (playerStatus === 'streamingLive' || !timelapseFrame) {
      return {
        imageUrl: camera.imageUrl,
        imageTimestamp: camera.imageTimestamp
      };
    }
    return {
      imageUrl: timelapseFrame.imageUrl,
      imageTimestamp: new Date(timelapseFrame.imageTimestamp)
    };
  }, [camera.imageTimestamp, camera.imageUrl, playerStatus, timelapseFrame]);

  return {
    playerStatus,
    // For extra security, never return a timelapse frame if the player is in live mode.
    timelapseFrame: playerStatus === 'streamingLive' ? null : timelapseFrame,
    timelapseFrameNumber,
    timelapseFramesLength: timelapseFrames.length,
    isLoading: isFetchingTimelapseFrames,
    timelapseReplayTime,
    imageUrl,
    imageTimestamp,

    setTimelapseFrameNumber,
    playTimelapse,
    pauseTimelapse,
    stopTimelapse,
    setTimelapseReplayTime,

    testOnlyAdvanceTimelapseFrame: advanceTimelapseFrame
  };
};

const fetchCameraTimeLapse = async (
  cameraId: string,
  replayTime: string
): Promise<CameraTimeLapseFrameDetails[]> => {
  const response = await CacheAPI.get<CameraTimeLapseFrameDetails[]>(
    `/cameras/timelapse/${cameraId}`,
    { params: { duration: replayTime } }
  );
  return response.data;
};

export const preloadImages = (urls: string[]): void => {
  if (!urls.length) return;
  for (const url of urls) {
    const image = new Image();
    image.src = url;
  }
};

export const useAlertCameraTimelapseFrames = (
  camera: AlertCamera,
  replayTime: number
): UseAlertCameraTimelapseFramesReturn => {
  const { isFetching, data, refetch } = useQuery({
    queryKey: ['camera-timelapse', camera.id, replayTime],
    queryFn: async () => {
      const frames = await fetchCameraTimeLapse(
        camera.id,
        replayTime.toString()
      );
      preloadImages(frames.map((value) => value.imageUrl));
      return frames;
    },
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false
  });

  const timelapseFrames = useMemo(() => data || [], [data]);
  return {
    isFetchingTimelapseFrames: isFetching,
    timelapseFrames,
    refetchTimelapseFrames: refetch
  };
};
