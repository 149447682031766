import { useEffect, useState } from 'react';

export type ImageLoadingStatus = 'loading' | 'success' | 'error';

/**
 * This hook tries to load an image programmatically so that the browser cache
 * will be able to show the image when it's needed for real. Note that the
 * "disable cache" setting in the browser's dev tools will prevent this from working.
 */
export const useImageLoader = (
  src?: string
): ImageLoadingStatus | undefined => {
  const [status, setStatus] = useState<ImageLoadingStatus | undefined>();

  const handleLoad = (): void => {
    setStatus('success');
  };

  const handleError = (): void => {
    setStatus('error');
  };

  useEffect(() => {
    if (!src) {
      return;
    }

    setStatus('loading');

    const image = new Image();
    image.onload = handleLoad;
    image.onerror = handleError;
    image.src = src;
  }, [src]);

  return status;
};
