import { useMapRenderBugDetector } from 'hooks/useMapRenderBugDetector';
import { useEffect } from 'react';

type MapRenderBugDetectorProps = {
  map: maplibregl.Map | undefined;
};

export const MapRenderBugDetector = (
  props: MapRenderBugDetectorProps
): JSX.Element | null => {
  const { map } = props;
  const isBugDetected = useMapRenderBugDetector(map);

  useEffect(() => {
    if (isBugDetected) {
      window.location.reload();
    }
  }, [isBugDetected]);

  return null;
};
