import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
  useMediaQuery,
  useTheme,
  Tooltip
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Editor, EditorContent, EditorEvents, useEditor } from '@tiptap/react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import KeyboardReturnSharpIcon from '@mui/icons-material/KeyboardReturnSharp';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useTranslation } from 'react-i18next';
import { deepOrange } from '@mui/material/colors';
import { getResponsiveFontSize, parseContentToHTML } from 'shared/utils';
import GrayButton from 'components/GrayButton';
import { UrlRegex } from '../../constants';
import { RICH_TEXT_EDITOR_EXTENSIONS } from './extensions';

type RichTextEditorProps = {
  id: string;
  initialValue: string;
  onChange: (html: string, text: string) => void;
  editable?: boolean;
  error?: boolean;
  label?: string;
  required?: boolean;
  editorClassName?: string;
  warning?: boolean;
};

type ToolbarProps = {
  editor: Editor | null;
};

type LinkDialogProps = {
  open: boolean;
  editor: Editor | null;
  onClose: () => void;
};

const useStyles = makeStyles<{ error?: boolean }>()(
  (theme, { error = false }) => {
    const borderColor = error
      ? theme.palette.error.main
      : theme.palette.grey[400];
    return {
      container: {
        border: `1px solid ${borderColor}`,
        borderRadius: theme.shape.borderRadius * 1.34
      },
      editor: {
        border: 'none',
        padding: '2px 16px',
        minHeight: 134,
        '&:focus': {
          outline: 'none !important'
        },
        '*': {
          fontFamily: theme.typography.fontFamily
        },
        '& p': {
          fontSize: getResponsiveFontSize(theme.typography.pxToRem(16))
        },
        '& a': {
          color: deepOrange[500]
        },
        '& li>p': {
          margin: 0
        }
      },
      toolbar: {
        border: 'none',
        padding: '8px 16px 0px',
        position: 'relative',
        '&::after': {
          content: '""',
          width: 'calc(100% - 32px)',
          height: 1,
          backgroundColor: theme.palette.grey[400],
          position: 'absolute',
          left: 16,
          bottom: 0
        }
      },
      button: {
        color: theme.palette.text.primary
      },
      dialogTitle: {
        paddingBottom: theme.spacing(1)
      },
      dialogActions: {
        padding: theme.spacing(2, 3, 3)
      },
      dialogButton: {
        minHeight: 48,
        textTransform: 'none',
        borderRadius: theme.shape.borderRadius * 1.34
      },
      label: {
        fontSize: '0.75em',
        backgroundColor: theme.palette.background.paper,
        width: 'fit-content',
        position: 'absolute',
        top: -10,
        left: 10,
        padding: '1px 4px'
      },
      warningContainer: {
        borderColor: theme.palette.warning.main,
        borderWidth: 2
      },
      labelWarning: {
        color: theme.palette.warning.main
      }
    };
  }
);

const LinkDialog = (props: LinkDialogProps): JSX.Element => {
  const { open, editor, onClose } = props;
  const { classes } = useStyles({});
  const { t } = useTranslation();
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!editor || !open) return;

    const existingHref = editor.isActive('link')
      ? editor.getAttributes('link').href
      : '';

    if (existingHref) {
      setUrl(existingHref);
    }
  }, [editor, open]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setUrl(event.target.value);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (url && !UrlRegex.test(url)) {
      setError(t('linkForm.input.invalid'));
    } else {
      setError('');
    }
  };

  const handleClose = (): void => {
    setUrl('');
    setError('');
    onClose();
  };

  const handleConfirm = (): void => {
    if (!editor || (url && !UrlRegex.test(url))) {
      return;
    }

    if (url) {
      // Update link
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    } else {
      // Remove link
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }

    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {t('linkForm.title')}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '8px !important' }}>
        <TextField
          autoFocus
          id="URL"
          label={t('linkForm.input.label')}
          fullWidth
          value={url}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <GrayButton onClick={handleClose} className={classes.dialogButton}>
          {t('common.cancel')}
        </GrayButton>
        <Button
          onClick={handleConfirm}
          color="primary"
          className={classes.dialogButton}
        >
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Toolbar = (props: ToolbarProps): JSX.Element | null => {
  const { editor } = props;
  const { classes } = useStyles({});
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const { t } = useTranslation();

  if (!editor) {
    return null;
  }

  const iconButtonSize = isLargeMediaQuery ? 'large' : 'medium';

  return (
    <div className={classes.toolbar}>
      <Tooltip placement="top" title={t('richTextEditor.formatBold')}>
        <IconButton
          className={classes.button}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          size={iconButtonSize}
        >
          <FormatBoldIcon
            fontSize="small"
            color={editor.isActive('bold') ? 'primary' : undefined}
          />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title={t('richTextEditor.formatItalics')}>
        <IconButton
          className={classes.button}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          size={iconButtonSize}
        >
          <FormatItalicIcon
            fontSize="small"
            color={editor.isActive('italic') ? 'primary' : undefined}
          />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title={t('richTextEditor.formatStrikethrough')}>
        <IconButton
          className={classes.button}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleStrike().run();
          }}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          size={iconButtonSize}
        >
          <StrikethroughSIcon
            fontSize="small"
            color={editor.isActive('strike') ? 'primary' : undefined}
          />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title={t('richTextEditor.addNumberedList')}>
        <IconButton
          className={classes.button}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          size={iconButtonSize}
        >
          <FormatListNumberedIcon
            fontSize="small"
            color={editor.isActive('orderedList') ? 'primary' : undefined}
          />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title={t('richTextEditor.addBulletedList')}>
        <IconButton
          className={classes.button}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          size={iconButtonSize}
        >
          <FormatListBulletedIcon
            fontSize="small"
            color={editor.isActive('bulletList') ? 'primary' : undefined}
          />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title={t('richTextEditor.addLink')}>
        <IconButton
          className={classes.button}
          onClick={() => setOpenLinkDialog(true)}
          size={iconButtonSize}
        >
          <LinkIcon
            fontSize="small"
            color={editor.isActive('link') ? 'primary' : undefined}
          />
        </IconButton>
      </Tooltip>

      <Tooltip placement="top" title={t('richTextEditor.removeLink')}>
        <span>
          <IconButton
            aria-label={t('richTextEditor.removeLink')}
            className={classes.button}
            onMouseDown={(event) => {
              event.preventDefault();
              editor.chain().focus().unsetLink().run();
            }}
            disabled={!editor.isActive('link')}
            size={iconButtonSize}
          >
            <LinkOffIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>

      <LinkDialog
        open={openLinkDialog}
        editor={editor}
        onClose={() => setOpenLinkDialog(false)}
      />

      <Tooltip placement="top" title={t('richTextEditor.addHardReturn')}>
        <IconButton
          className={classes.button}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.commands.setHardBreak();
          }}
          size={iconButtonSize}
        >
          <KeyboardReturnSharpIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const RichTextEditor = (props: RichTextEditorProps): JSX.Element => {
  const {
    id,
    initialValue,
    onChange,
    editable = true,
    error = false,
    label = '',
    required = false,
    editorClassName = '',
    warning = false
  } = props;
  const { classes, cx } = useStyles({ error });

  const handleUpdate = useCallback(
    (updateEvent: EditorEvents['update']) => {
      const html = updateEvent.editor.getHTML();
      const text = updateEvent.editor.getText();
      onChange(html, text);
    },
    [onChange]
  );

  const editor = useEditor(
    {
      extensions: RICH_TEXT_EDITOR_EXTENSIONS,
      content: parseContentToHTML(initialValue),
      editorProps: {
        attributes: { class: cx(classes.editor, editorClassName), id }
      },
      onUpdate: handleUpdate
    },
    [initialValue]
  );

  return (
    <Box
      sx={{
        position: 'relative',
        pointerEvents: editable ? 'auto' : 'none',
        opacity: editable ? 1 : 0.33
      }}
    >
      {!!label && (
        <InputLabel
          required={required}
          className={cx(classes.label, warning && classes.labelWarning)}
          error={error}
        >
          {label}
        </InputLabel>
      )}
      <div
        className={cx(classes.container, warning && classes.warningContainer)}
      >
        <Toolbar editor={editor} />
        <EditorContent editor={editor} />
      </div>
    </Box>
  );
};

export default RichTextEditor;
