import { ChangeEvent } from 'react';
import { List, ListItemButton, ListItemText, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { EvacZone, FormEvacZoneStatus } from 'shared/types';
import { getDeviceInfo } from 'state/localStorageTyped';

type MultiSelectFieldProps = {
  id: string;
  value: string[];
  options: (EvacZone | FormEvacZoneStatus)[];
  onChange: (
    event: ChangeEvent<HTMLSelectElement> | { zoneUIdV2: string }
  ) => void;
  selectClassName?: string;
};

type SelectListProps = Omit<MultiSelectFieldProps, 'selectClassName'>;

const useStyles = makeStyles()((theme) => ({
  multiSelect: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 1.34
  },
  select: {
    width: '100%',
    height: '100% !important',
    minHeight: 215,
    padding: theme.spacing(2, 0),
    paddingRight: '0px !important'
  },
  option: {
    padding: '6px 12px'
  },
  listContainer: {
    width: '100%',
    height: '100%',
    maxHeight: 270,
    overflow: 'hidden'
  },
  selectList: {
    width: '100%',
    height: '100%',
    maxHeight: 270,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius * 1.34,
    overflow: 'auto'
  }
}));

const SelectList = (props: SelectListProps): JSX.Element => {
  const { id, value, options, onChange } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.listContainer}>
      <List id={id} className={classes.selectList} dense>
        {options.map((zone) => {
          if ('status' in zone) {
            return (
              <ListItemButton
                key={zone.evacZone.uidV2}
                onClick={() => onChange({ zoneUIdV2: zone.evacZone.uidV2 })}
                selected={value.includes(zone.evacZone.uidV2)}
                dense
              >
                <ListItemText primary={zone.evacZone.displayName} />
              </ListItemButton>
            );
          }
          return (
            <ListItemButton
              key={zone.uidV2}
              onClick={() => onChange({ zoneUIdV2: zone.uidV2 })}
              selected={value.includes(zone.uidV2)}
              dense
            >
              <ListItemText primary={zone.displayName} />
            </ListItemButton>
          );
        })}
      </List>
    </div>
  );
};

const MultiSelectField = (props: MultiSelectFieldProps): JSX.Element => {
  const { id, value, options, onChange, selectClassName } = props;
  const { classes, cx } = useStyles();
  const deviceInfo = getDeviceInfo();

  if (deviceInfo?.isMobilePlatform) {
    return <SelectList {...props} />;
  }

  return (
    <Select
      native
      multiple
      value={value}
      // @ts-ignore Typings are not considering `native`
      onChange={onChange}
      inputProps={{ id }}
      className={classes.multiSelect}
      classes={{ select: cx(classes.select, selectClassName) }}
    >
      {options.map((zone) => {
        if ('status' in zone) {
          return (
            <option
              key={zone.evacZone.uidV2}
              value={zone.evacZone.uidV2}
              className={classes.option}
            >
              {zone.evacZone.displayName}
            </option>
          );
        }
        return (
          <option
            key={zone.uidV2}
            value={zone.uidV2}
            className={classes.option}
          >
            {zone.displayName}
          </option>
        );
      })}
    </Select>
  );
};

export default MultiSelectField;
