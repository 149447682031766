import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import { UrlRegex } from '../../constants';

export const RICH_TEXT_EDITOR_EXTENSIONS = [
  StarterKit.configure({
    heading: false,
    blockquote: false,
    codeBlock: false,
    horizontalRule: false,
    code: false
  }),
  Link.extend({ inclusive: false }).configure({
    openOnClick: false,
    autolink: true,
    validate: (href) => UrlRegex.test(href)
  })
];
