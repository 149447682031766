import { atom, useRecoilState } from 'recoil';

/*
 * live = showing the latest camera image with automatic refresh
 * playing = playing a timelapse
 * paused = paused in timelapse mode
 */
export type PlayerStatus =
  | 'streamingLive'
  | 'playingTimelapse'
  | 'pausedTimelapse';

export type CameraTimeLapseFrameDetails = {
  imageTimestamp: string;
  imageUrl: string;
  azDeg: number | null;
  fovDeg: number | null;
};

type UseAlertCameraPlayerStateReturn = {
  playerStatus: PlayerStatus;
  timelapseFrame: CameraTimeLapseFrameDetails | null;
};

export const cameraStatusAtom = atom<PlayerStatus>({
  key: 'ALERT_CAMERA_PLAYER_STATUS_STATE',
  default: 'streamingLive'
});

export const cameraTimelapseFrameAtom =
  atom<CameraTimeLapseFrameDetails | null>({
    key: 'ALERT_CAMERA_TIMELAPSE_FRAME_STATE',
    default: null
  });

/*
 * A read-only version of the player state for components that wish to only
 * observe the latest player state.
 */
export const useAlertCameraPlayerState =
  (): UseAlertCameraPlayerStateReturn => {
    const [playerStatus] = useRecoilState<PlayerStatus>(cameraStatusAtom);
    const [timelapseFrame] = useRecoilState<CameraTimeLapseFrameDetails | null>(
      cameraTimelapseFrameAtom
    );

    return {
      playerStatus,
      // For extra security, never return a timelapse frame if the player is in live mode.
      timelapseFrame: playerStatus === 'streamingLive' ? null : timelapseFrame
    };
  };
