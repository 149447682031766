import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import HeaderBar from 'components/HeaderBar';
import MembershipIAPCheckoutContent from './MembershipIAPCheckoutContent';

const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('membershipCheckout.title')}</title>
      </Helmet>
      <MembershipIAPCheckoutContent />
    </>
  );
};

const MembershipIAPCheckout = (): JSX.Element => {
  return (
    <PageLayout data-cname="MembershipCheckoutPage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: <HeaderBar action="back" hideAction />
      }}
    </PageLayout>
  );
};

export default MembershipIAPCheckout;
