import { API, MapBoxAPI } from '../../api';
import { GeoEvent, LatLng } from '../../shared/types';
import {
  getValidRegExp,
  parseDMS,
  parseLatitudeParam,
  parseLongitudeParam
} from '../../shared/utils';
import { GeocodeResult } from './useMapSearch.types';

const AUTOCOMPLETE_RADIUS = 500;

type GoogleCoordsData = {
  coords: [lng: number, lat: number];
  formattedAddress: string;
};

/**
 * Takes an HTML string as input and checks if it contains any <b> tags.
 * If it doesn't, the function returns the original string as-is. However,
 * if <b> tags are present, the function extracts the content within the
 * first set of <b> tags and wraps it in a <b> JSX element. It then recursively
 * parses the text before and after that <b> tag, and finally, combines
 * all the parsed parts into a single JSX element.
 */
const parseHTML = (html: string): JSX.Element | string => {
  const regex = /<b>(.*?)<\/b>/g;
  const match = regex.exec(html);
  if (!match) {
    return html;
  }
  const [, innerHTML] = match;
  const beforeHTML = html.slice(0, match.index);
  const afterHTML = html.slice(match.index + match[0].length);
  return (
    <>
      {parseHTML(beforeHTML)}
      <b>{innerHTML}</b>
      {parseHTML(afterHTML)}
    </>
  );
};

export const getMapSearchResultName = (location: GeocodeResult): string =>
  `${location.address || ''} ${location.text}`.trim();

export const getHighlightedSearchMatch = (
  searchTerm: string,
  resultName: string
): string | JSX.Element | JSX.Element[] => {
  const regex = getValidRegExp(`(${searchTerm.trim()})`, 'i');
  if (!regex) return resultName;
  const parsedResult = resultName.replace(regex, '<b>$1</b>');
  return parseHTML(parsedResult);
};

export const getGoogleCoords = async (
  placeId: string
): Promise<GoogleCoordsData | null> => {
  if (!window.google) {
    return null;
  }

  const geocoder = new window.google.maps.Geocoder();
  const { results } = await geocoder.geocode({ placeId });

  const place = results[0];

  if (!place) {
    return null;
  }

  return {
    coords: [place.geometry.location.lng(), place.geometry.location.lat()],
    formattedAddress: place.formatted_address
  };
};

export const fetchGeoEventsForSearch = async (
  term: string,
  inactive: boolean = false
): Promise<GeoEvent[]> => {
  const params = {
    q: term,
    limit: 100,
    reporter_managed_only: false,
    ...(inactive ? { inactiveOnly: true } : {})
  };
  const res = await API.get(`geo_events/search`, {
    params
  });
  return res.data.results;
};

export const fetchGoogleMapsSearch = async (
  query: string,
  proximity: string
): Promise<GeocodeResult[]> => {
  if (!window.google) {
    return [];
  }
  const mapsService = new window.google.maps.places.AutocompleteService();

  const [lng, lat] = proximity.split(',').map((e) => parseFloat(e.trim()));

  const googleMapsPlacesRequest: google.maps.places.AutocompletionRequest = {
    location: new window.google.maps.LatLng(lat, lng),
    radius: AUTOCOMPLETE_RADIUS,
    input: query,
    region: 'us'
  };

  const { predictions } = await mapsService.getPlacePredictions(
    googleMapsPlacesRequest
  );

  if (!predictions) {
    return [];
  }

  return predictions.map((result) => ({
    id: result.place_id,
    type: 'Feature',
    text: result.structured_formatting.main_text,
    placeName: result.description,
    center: [] // Placeholder, this will be updated for the selected place
  }));
};

export const fetchMapboxSearch = async (
  term: string,
  proximity: string
): Promise<GeocodeResult[]> => {
  const res = await MapBoxAPI.get<{
    type: 'FeatureCollection';
    query: string[];
    features: GeocodeResult[];
  }>(`/${encodeURIComponent(term)}.json`, {
    params: {
      access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN ?? '',
      proximity,
      types:
        'country,region,district,postcode,locality,place,address,poi,neighborhood',
      language: 'en',
      limit: '5',
      country: 'us'
    }
  });

  return res.data.features;
};

export const getLatLngFromString = (searchTerm: string): LatLng | null => {
  // Allow lat lng values separated by comma or whitespace.
  const rawValues = (
    searchTerm.includes(',')
      ? searchTerm.split(',')
      : searchTerm.trim().split(/\s+/)
  ).map((part) => part.trim());

  if (rawValues.length === 2) {
    const lat = parseLatitudeParam(rawValues[0]);
    const lng = parseLongitudeParam(rawValues[1]);

    if (lat && lng) {
      return { lat, lng: lng < 0 ? lng : lng * -1 };
    }
  }

  const { latitude, longitude } = parseDMS(searchTerm);

  if (latitude && longitude)
    return { lat: latitude, lng: longitude < 0 ? longitude : longitude * -1 };

  return null;
};
