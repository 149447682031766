import {
  IconButton,
  Slider as MuiSlider,
  SliderOwnProps,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks';
import moment from 'moment';
import { getTimePass, isPhone } from 'shared/utils';
import { useAlertCameraPlayer } from 'hooks/useAlertCameraPlayer';
import { AlertCamera } from 'hooks/useAlertCameras';
import { TFunction } from 'i18next';
import { CameraImage } from './CameraImage';
import { CameraFullScreenButton } from './CameraFullScreenButton';
import { CameraPreview } from './CameraPreview';

type CameraPlayerProps = {
  camera: AlertCamera;
  fullScreen?: boolean;
};

const REPLAY_TIME_OPTIONS_SECONDS = [
  60 * 5, // 5 minutes
  60 * 10, // 10 minutes
  60 * 15, // 15 minutes
  60 * 30, // 30 minutes
  60 * 60, // 1 hour
  60 * 60 * 2, // 2 hours
  60 * 60 * 3, // 3 hours
  60 * 60 * 6 // 6 hours
];

const isReplayTimeEnabledForCamera = (
  camera: AlertCamera,
  replayTime: number
): boolean => camera.hasPtz || replayTime / 60 >= 30;

const Slider = withStyles(MuiSlider, (theme) => ({
  root: {
    height: 8,
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  thumb: {
    width: 14,
    height: 14,
    border: '2px solid white',
    '&:before': {
      boxShadow: 'none'
    },
    boxShadow: theme.shadows[1]
  },
  track: {
    height: 6,
    borderRadius: 4
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: theme.palette.grey[400]
  }
}));

const useStyles = makeStyles<{ fullScreen: boolean }>()(
  (theme, { fullScreen }) => ({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    button: {
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey[500]
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[700]
      }
    },
    menuPaper: {
      backgroundColor: fullScreen
        ? theme.palette.background.paper
        : theme.palette.grey[900],
      color: fullScreen
        ? theme.palette.text.primary
        : theme.palette.background.paper
    },
    replayTimeButton: {
      fontWeight: 'bold',
      '.MuiButton-endIcon': {
        marginLeft: '2px'
      },
      '&:disabled': {
        color: fullScreen
          ? theme.palette.background.paper
          : theme.palette.text.primary
      }
    }
  })
);

export const parseReplayTime = (seconds: number, t: TFunction): string => {
  const hours = Math.floor(seconds / 60 / 60);
  if (hours) return t('common.hours', { count: hours });
  const minutes = Math.floor((seconds / 60) % 60);
  return t('common.minutes', { count: minutes });
};

const CameraPlayer = (props: CameraPlayerProps): JSX.Element => {
  const { camera, fullScreen = false } = props;
  const {
    playerStatus,
    timelapseFrameNumber,
    timelapseFramesLength,
    timelapseReplayTime,
    imageUrl,
    imageTimestamp,
    isLoading,
    setTimelapseFrameNumber,
    playTimelapse,
    pauseTimelapse,
    stopTimelapse,
    setTimelapseReplayTime
  } = useAlertCameraPlayer(camera);
  const { classes } = useStyles({ fullScreen });
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'playTimeMenu'
  });

  const handleTogglePlay = async (): Promise<void> => {
    if (playerStatus === 'playingTimelapse') {
      pauseTimelapse();
      return;
    }
    playTimelapse();
  };

  const handleStopTimelapse = async (): Promise<void> => {
    stopTimelapse();
  };

  const handleSliderChange: SliderOwnProps['onChange'] = async (
    _,
    value
  ): Promise<void> => {
    pauseTimelapse();
    setTimelapseFrameNumber(value as number);
  };

  const handleSetReplayTime = (time: number): void => {
    setTimelapseReplayTime(time);
    popupState.close();
  };

  const fullscreenButtonVisible = !fullScreen && !isPhone();

  const currentFrameTimeString = moment(imageTimestamp).format('MMM D hh:mm a');
  const currentFrameTimeRelativeString = ` • ${getTimePass(
    imageTimestamp.toISOString()
  )}`;

  return (
    <Stack spacing={0}>
      {playerStatus === 'streamingLive' ? (
        <CameraPreview url={imageUrl} loading={isLoading} />
      ) : (
        <CameraImage url={imageUrl} loading={isLoading} />
      )}

      <Typography
        variant="subtitle1"
        fontWeight="bold"
        paragraph
        marginTop={0.5}
        marginBottom={0.5}
      >
        {currentFrameTimeString}
        {currentFrameTimeRelativeString}
      </Typography>

      <Slider
        color="accent"
        size="small"
        value={timelapseFrameNumber}
        onChange={handleSliderChange}
        min={0}
        max={Math.max(timelapseFramesLength - 1, 1)} // max at least 1 so that a value of zero displays correctly
        disabled={isLoading || playerStatus === 'streamingLive'}
      />

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        marginTop={1}
      >
        <Stack direction="row" spacing={1}>
          <IconButton
            className={classes.button}
            onClick={handleTogglePlay}
            disabled={isLoading}
          >
            {playerStatus === 'playingTimelapse' ? (
              <PauseIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            ) : (
              <PlayArrowIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            )}
          </IconButton>
          {playerStatus !== 'streamingLive' && (
            <IconButton
              className={classes.button}
              onClick={handleStopTimelapse}
              disabled={isLoading}
            >
              <StopIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            </IconButton>
          )}
        </Stack>

        <Stack direction="row">
          <Button
            {...bindTrigger(popupState)}
            disabled={isLoading}
            variant="text"
            color="inherit"
            size="small"
            endIcon={
              <ExpandMoreIcon
                fontSize="medium"
                sx={{ color: fullScreen ? 'background.paper' : 'text.primary' }}
              />
            }
            className={classes.replayTimeButton}
          >
            {parseReplayTime(timelapseReplayTime, t)}
          </Button>

          {fullscreenButtonVisible && (
            <CameraFullScreenButton cameraId={camera.id} disabled={isLoading} />
          )}
        </Stack>
      </Stack>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ paper: classes.menuPaper }}
      >
        {REPLAY_TIME_OPTIONS_SECONDS.map((time) => (
          <MenuItem
            key={time}
            sx={{ padding: '4px 16px' }}
            onClick={() => handleSetReplayTime(time)}
            disabled={isLoading || !isReplayTimeEnabledForCamera(camera, time)}
          >
            <Typography key={time} variant="subtitle1" component="p">
              {parseReplayTime(time, t)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default CameraPlayer;
