import { SxProps } from '@mui/material';
import { TFunction } from 'i18next';
import { findFuzzyMatch } from 'shared/utils';

// https://www.gmass.co/domains
const EMAIL_PROVIDERS = [
  'gmail',
  'yahoo',
  'hotmail',
  'aol',
  'outlook',
  'comcast',
  'icloud',
  'msn',
  'sbcglobal',
  'live',
  'me',
  'att',
  'mail',
  'bellsouth',
  'rediffmail',
  'cox',
  'verizon',
  'ymail',
  'kw',
  'mac',
  'googlemail',
  'libero',
  'web',
  'allstate',
  'btinternet',
  'online',
  'earthlink',
  'gmx',
  'shawinc',
  'moe',
  '163',
  'naver',
  'bigpond',
  'statefarm',
  'remax',
  'rocketmail',
  'yandex',
  'qq',
  'charter',
  'indeedemail',
  'alice',
  'bluewin',
  'optonline',
  'wp',
  'pindotmedia',
  'orange',
  'morganstanley',
  'wellsfargo',
  'juno',
  'wanadoo',
  'facebook',
  'edwardjones',
  'fema',
  'rogers',
  'nic',
  'nab',
  'ubs',
  'uol',
  'shaw',
  't-online',
  'umich',
  'westpac',
  'farmersagent',
  'anz',
  'dhs',
  ...(import.meta.env.VITE_ENV !== 'production' ? ['mailinator'] : [])
];

export const getEmailInputSxProp = (input: {
  registered: boolean;
}): SxProps => {
  const { registered } = input;
  return {
    ...(registered ? { input: { color: 'error.main' } } : {})
  };
};

export const getEmailProviderFuzzyMatch = (
  t: TFunction,
  email: string
): string => {
  const [, domain = ''] = email.split('@');
  const [provider, tld] = domain.split('.');
  if (!provider) return '';
  const fuzzyMatch = findFuzzyMatch({
    query: provider,
    candidates: EMAIL_PROVIDERS
  });
  if (!fuzzyMatch) return '';
  return t('common.fuzzyMatch', {
    match: `${fuzzyMatch}${tld ? `.${tld}` : ''}`
  });
};
