export const SOURCE_ID = 'evac_zones_ca';
export const SOURCE_LAYER = 'zones';
export const EVAC_ZONES_EDIT_FILL_LAYER_ID = 'zones-fill';
export const VIEW_SOURCE_ID = `${SOURCE_ID}_VIEW`;

export const EDIT_FILL_LAYER_GREEN_ADVISORIES_ID =
  'zones-fill-green-advisories';
export const EDIT_FILL_LAYER_BLUE_ADVISORIES_ID = 'zones-fill-blue-advisories';
export const EDIT_FILL_LAYER_WARNINGS_ID = 'zones-fill-warnings';
export const EDIT_FILL_LAYER_ORDERS_ID = 'zones-fill-orders';
export const EDIT_FILL_LAYER_REVERSE_ADVISORIES_ID =
  'zones-fill-reverse-advisories';
export const EDIT_FILL_LAYER_REVERSE_ORDERS_ID = 'zones-fill-reverse-orders';

// always show with minzoom at 1
export const ACTIVE_ZONES_LAYER_MIN_ZOOM = 1;
export const INACTIVE_ZONES_LAYER_MIN_ZOOM = 9;
export const LABEL_LAYER_MIN_ZOOM = 9;

// The field in the maptiles features that contains the zone uid.
export const FEATURE_UID_V2_FIELD_NAME = 'zone_uid_v2';
