import { LngLat, LngLatBounds } from 'maplibre-gl';
import { RateApp } from 'capacitor-rate-app';
import * as Sentry from '@sentry/capacitor';
import moment from 'moment';
import i18n from 'i18next';
import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import { Photo } from '@watchduty/camera';
import axios from 'axios';
import { App } from '@capacitor/app';
import levenshtein from 'js-levenshtein';
import { generateJSON, generateText } from '@tiptap/react';
import { RICH_TEXT_EDITOR_EXTENSIONS } from 'components/RichTextEditor/extensions';
import {
  GeoEvent,
  GeoEventEvacZoneStatus,
  GeoEventRegion,
  GPSData,
  MapLocation,
  MapSearchResult,
  NotificationRegion,
  NotificationSetting,
  PlaceAddress,
  PlaceLocation,
  RegionEvacZoneStyle,
  RegionSection,
  UploadImageData,
  User,
  UserGroups
} from './types';
import {
  EvacZoneStyles,
  NotificationSettingTypes,
  NotificationTypes,
  PhoneNumberRegex,
  UsStatesAbbr
} from '../constants';

const changeTimeZone = (
  date: Date | string,
  timeZone: string,
  options: Partial<Intl.DateTimeFormatOptions> = {}
): string => {
  if (typeof date === 'string') {
    return new Date(date).toLocaleString('en-US', {
      timeZone,
      ...options
    });
  }

  return date.toLocaleString('en-US', {
    timeZone,
    ...options
  });
};

/**
 * @author:davidofwatkins Not sure why dates are coming back without any timezone, but we should probably
 * fix this from the serverside. This check will prevent the front-end from breaking if we do that far
 * enough in the future.
 */
export const setNaiveISODateStringToUTC = (isoString: string): string =>
  isoString.includes('+') || isoString.endsWith('Z')
    ? isoString
    : `${isoString}Z`;

export const getTimePass = (time: string): string =>
  moment(setNaiveISODateStringToUTC(time)).fromNow();

export const getDateFormatted = (
  time: string,
  options?: { includeYear?: boolean }
): string => {
  const mdate = moment(setNaiveISODateStringToUTC(time));
  const combiner = i18n.t('common.at');
  const dateFormat = options?.includeYear ? 'MMM D, YYYY' : 'MMM D';
  return `${mdate.format(dateFormat)} ${combiner} ${mdate.format('h:mm A')}`;
};

export const getDateFormattedGeoEvent = (
  time: string,
  options?: { includeYear?: boolean }
): string => {
  const mdate = moment(setNaiveISODateStringToUTC(time));
  const dateFormat = options?.includeYear ? 'MMM D, YYYY' : 'MMM D';
  return `${mdate.format(dateFormat)}, ${mdate.format('h:mm A')}`;
};

export const getPDTDateFormatted = (time: string): string => {
  const timeZone = 'America/Los_Angeles';
  const dateStr = setNaiveISODateStringToUTC(time);

  const pdtDate = changeTimeZone(dateStr, timeZone);
  const timeZoneName = changeTimeZone(dateStr, timeZone, {
    timeZoneName: 'short'
  })
    .split(' ')
    .pop();

  return `${moment(new Date(pdtDate)).format('MMM D, h:mm A')} ${timeZoneName}`;
};

export const getDateFormattedInboxMessage = (time: string): string => {
  const mdate = moment(setNaiveISODateStringToUTC(time));
  return mdate.format('dddd, MMM D [at] h:mm A');
};

// Returns an array of [acreage number as string, singular/plural form of "acre"]
export const getAcreageStrParts = (
  acreage: number | null,
  containment: number | null,
  locale?: string
): Array<string | null> => {
  // This conditional matches the logic used in src/components/IncidentCard to decide
  // when to display the incident/containment boxes.
  if (!acreage && !containment) return [null, null];
  if (acreage === null) return [null, null];
  if (acreage === 0) return ['< 1', 'acre'];
  if (acreage === 1) return ['1', 'acre'];
  const formattedAcreage = locale
    ? Intl.NumberFormat(locale).format(acreage)
    : acreage.toLocaleString();
  return [formattedAcreage, 'acres'];
};

/**
 * This function groups notification settings counties by state, using
 * an object which keys are the notification region settings "stateDisplayName"
 * (e.g. "California"), and also checks if all counties in a state
 * are not Reporter Covered in which case the county's region "isReporterCoveredForFire"
 * property is false.
 */
export const getRegionSettingsSections = (
  regionSettings: NotificationSetting[],
  searchQuery: string | null
): Record<string, RegionSection> => {
  const sections: Record<string, RegionSection> = {};
  const searchQueryLower = searchQuery?.toLowerCase();

  regionSettings.forEach((regionSetting) => {
    // only include regions if they match the search query (if search query exists)
    if (searchQueryLower) {
      const matchesSearch = regionSetting.region.displayName
        .toLowerCase()
        .includes(searchQueryLower);
      if (!matchesSearch) return null;
    }

    if (!sections[regionSetting.region.stateDisplayName]) {
      sections[regionSetting.region.stateDisplayName] = {
        title: regionSetting.region.stateDisplayName,
        regionSettings: [],
        isReporterCoveredForFire: false
      };
    }

    sections[regionSetting.region.stateDisplayName].regionSettings.push(
      regionSetting
    );

    if (
      !sections[regionSetting.region.stateDisplayName]
        .isReporterCoveredForFire &&
      regionSetting.region.isReporterCoveredForFire
    ) {
      sections[regionSetting.region.stateDisplayName].isReporterCoveredForFire =
        true;
    }

    return sections;
  });

  return sections;
};

export const getSortedRegionsSections = (
  regionSettings: NotificationSetting[],
  searchQuery: string | null
): RegionSection[] => {
  const regionSections = getRegionSettingsSections(regionSettings, searchQuery);

  return Object.keys(regionSections)
    .sort()
    .map((title) => regionSections[title]);
};

export const getActiveSettings = (
  regionSettings: NotificationSetting[]
): NotificationSetting[] =>
  regionSettings.filter(
    (regionSetting) => regionSetting.setting !== NotificationTypes.OFF.key
  );

/**
 *
 * @param timeMs delay in miliseconds
 */
export const waitFor = (timeMs: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), timeMs));

export const userInGroups = (
  user: User | null,
  groupNames: UserGroups[]
): boolean => {
  if (!user) {
    return false;
  }
  // legacy local storage user does not have user.groups
  if (!user.groups) {
    return false;
  }
  return user.groups.some((group) => groupNames.includes(group.name));
};

export const showDevelopmentFeature = (user: User | null): boolean => {
  // allows internal users to see things in production before release, and always in preprod environments
  // indepent of user state.
  if (import.meta.env.VITE_ENV !== 'production') {
    return true;
  }
  return userInGroups(user, ['internal_users']);
};

export const showPreprodDevFeature = (): boolean => {
  // only show in preproduction environments
  return import.meta.env.VITE_ENV !== 'production';
};

export const getRandomId = (): number => {
  return Math.floor(Math.random() * Date.now());
};

// Stolen from here - https://stackoverflow.com/a/30773300
const metersPerPixel = (latitude: number, zoomLevel: number): number => {
  const earthRadius = 6378137;
  const tileSize = 512;
  const earthCircumference = 2 * Math.PI * earthRadius;
  const scale = Math.pow(2, zoomLevel);
  const worldSize = tileSize * scale;
  const latitudeRadians = latitude * (Math.PI / 180);
  return (earthCircumference * Math.cos(latitudeRadians)) / worldSize;
};

export const metersToPixels = (
  latitude: number,
  meters: number,
  zoomLevel: number
): number => {
  return meters / metersPerPixel(latitude, zoomLevel);
};

/** @deprecated by getMapSearchResultName() in useMapSearch() */
export const getMapSearchResultName = (location: MapSearchResult): string => {
  let name = '';
  if (location.address) name += `${location.address} `;
  name += location.text;
  return name;
};

export const removeAddressCountry = (address: string): string => {
  return address.replace(/(,\s)?united states/i, '');
};

export const abbrState = (address: string): string => {
  return address.replace(/\b([A-Z][a-z]+(?: [A-Z][a-z]+)?)\b/g, (match) => {
    return UsStatesAbbr[match] || match;
  });
};

export const getMapSearchResultFormattedAddress = (
  name: string,
  placeName: string
): string => {
  return abbrState(
    removeAddressCountry(
      placeName.replace(name, '').split(',').filter(Boolean).join(',')
    )
  );
};

export const mapSearchResultToMapLocation = (
  result: MapSearchResult
): MapLocation => {
  const name = getMapSearchResultName(result);
  return {
    name,
    lat: result.center[1],
    lng: result.center[0],
    bbox: result.bbox,
    place: false,
    formattedAddress:
      result.placeAddress ||
      getMapSearchResultFormattedAddress(name, result.placeName),
    type: result.type,
    geoEventId: parseInt(result.id, 10)
  };
};

export const mapLocationToPlaceAddress = (
  location: MapLocation
): PlaceAddress => {
  return {
    name: location.name,
    formattedAddress: location.formattedAddress ?? '',
    coordinates: {
      latitude: location.lat,
      longitude: location.lng
    },
    bbox: location.bbox,
    type: location.type
  };
};

export const placeLocationToMapLocation = (
  location: PlaceLocation
): MapLocation => {
  return {
    name: location.name,
    lat: location.address.coordinates.latitude,
    lng: location.address.coordinates.longitude,
    bbox: location.address.bbox,
    place: true
  };
};

export const knotsToMph = (knots: number): number => {
  return Math.round(knots * 1.15078);
};

export const replaceAtIndex = <T>(array: T[], index: number, item: T): T[] => {
  const cloneArray = [...array];

  cloneArray.splice(index, 1, item);

  return cloneArray;
};

export const formatToUSDollars = (amount: number, digits = 0): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits
  }).format(amount);
};

export const getValidRegExp = (term: string, flags?: string): RegExp | null => {
  try {
    const regExp = new RegExp(term, flags);
    return regExp;
  } catch (error) {
    return null;
  }
};

export const getDateStringWithNoZeroTimeZone = (): string =>
  new Date().toISOString().slice(0, -1);

export const parseNumericString = (value: string): number => {
  if (!value) return 0;
  const parsedNumber = parseFloat(value);
  if (isNaN(parsedNumber)) return 0;
  return parsedNumber;
};

export const isValidLat = (value: number): boolean => {
  return value >= -90 && value <= 90;
};

export const isValidLng = (value: number): boolean => {
  return value >= -180 && value <= 180;
};

/**
 * Truncates a string to a given length ensuring the result always
 * ends at the end of a complete word. It avoids breaking words,
 * which may lead to the returned result being slightly longer than
 * the designated maximum length.
 * @param str - string to truncate
 * @param limit - maximum length to truncate
 * @returns truncated string
 */
export const removeNewLines = (str: string): string => {
  // Removing line breaks from str
  return str.replace(/(\r\n|\n|\r)/gm, ' ');
};

export const truncateString = (str: string, limit: number): string => {
  if (str.length <= limit) return str;

  // Minus ellipsis characters length
  const spaceAfterLimitIndex = str.indexOf(' ', limit - 3);

  if (spaceAfterLimitIndex === -1) return str;

  return `${str.slice(0, spaceAfterLimitIndex).replace(/\.$/g, '')}...`;
};

export const getValidCoordinateParams = (
  coordinates: string
): {
  latitudeParam: number;
  longitudeParam: number;
  zoomLevelParam?: number;
} | null => {
  const [maybeLat, maybeLng, maybeZoom] = coordinates?.split(',') ?? [];
  const parsedLatitude = parseLatitudeParam(maybeLat);
  const parsedLongitude = parseLongitudeParam(maybeLng);
  const parsedZoomLevel = Number(maybeZoom?.toLowerCase().replace('z', ''));
  if (!parsedLatitude || !parsedLongitude) {
    return null;
  }
  if (isNaN(parsedZoomLevel))
    return {
      latitudeParam: parsedLatitude,
      longitudeParam: parsedLongitude
    };
  return {
    latitudeParam: parsedLatitude,
    longitudeParam: parsedLongitude,
    zoomLevelParam: parsedZoomLevel
  };
};

export const parseLatitudeParam = (latitude: string): number | undefined => {
  const lat = Number(latitude);
  if (!lat || isNaN(lat) || !isValidLat(lat)) return undefined;
  return lat;
};

export const parseLongitudeParam = (longitude: string): number | undefined => {
  const lng = Number(longitude);
  if (!lng || isNaN(lng) || !isValidLng(lng)) return undefined;
  return lng;
};

export const parseZoomParam = (zoom: string): number | undefined => {
  const zoomLevel = Number(zoom);
  if (!zoomLevel || isNaN(zoomLevel)) return undefined;
  return zoomLevel;
};

/**
 *
 * @param fontSize rem units
 * @returns
 */
export const getResponsiveFontSize = (fontSize: string): string => {
  const [rem] = (fontSize.match(/\.?\d+(\.\d+)?/) ?? [1]) as number[];
  const pixels = rem * 16; // 16px = 1rem - default
  const maxSize = pixels * 1.35; // 1.35x
  return `min(${fontSize}, ${maxSize}px)`;
};

export const getRegionName = (regions: GeoEventRegion[]): string => {
  const region = regions[0];
  if (!region) return '';
  let name = `${region.displayName}, ${region.state}`;
  if (regions.length > 1) {
    name += ' & ...';
  }
  return name;
};

const convertDMSToDD = (
  degrees: number,
  minutes: number,
  seconds: number,
  direction?: string
): number => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60);

  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  }
  // Don't do anything for N or E

  return dd;
};

// https://stackoverflow.com/questions/1140189/converting-latitude-and-longitude-to-decimal-values
export const parseDMS = (
  input: string
): { latitude: number; longitude: number } => {
  const parts = input.split(/[^\d\w-.]+/).filter(Boolean);
  let lat = 0;
  let lng = 0;
  if (parts.length === 8) {
    lat = convertDMSToDD(
      Number(parts[0]),
      Number(parts[1]),
      Number(parts[2]),
      parts[3]
    );
    lng = convertDMSToDD(
      Number(parts[4]),
      Number(parts[5]),
      Number(parts[6]),
      parts[7]
    );
  } else if (parts.length === 4) {
    const latDegrees = Number(parts[0]);
    const [ltMins, ltSecsDec] = parts[1].split('.');
    const latMins = Number(ltMins);
    const latSecs = Number(`.${ltSecsDec}`) * 60; // To find the seconds, multiply the decimal part by 60

    const lngDegrees = Number(parts[2]);
    const [lnMins, lnSecsDec] = parts[3].split('.');
    const lngMins = Number(lnMins);
    const lngSecs = Number(`.${lnSecsDec}`) * 60; // To find the seconds, multiply the decimal part by 60

    lat =
      convertDMSToDD(Math.abs(latDegrees), latMins, latSecs) *
      (latDegrees < 0 ? -1 : 1);
    lng =
      convertDMSToDD(Math.abs(lngDegrees), lngMins, lngSecs) *
      (lngDegrees < 0 ? -1 : 1);
  }
  return {
    latitude: parseFloat(lat.toFixed(6)),
    longitude: parseFloat(lng.toFixed(6))
  };
};

export const getLatLngLocationName = (lat: number, lng: number): string => {
  return `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
};

/*
Generates 24 char randomized alphanumeric value. This is stored on recurring payment intent POST and
later validated for a non-password login after a user is created automatically
 */
export const generateClientToken = (): string =>
  Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);

export const promptUserToRateApp = async (): Promise<void> => {
  if (!Capacitor.isNativePlatform()) return;
  try {
    await RateApp.requestReview();
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const parsePhoneNumberString = (value: string): string => {
  return value.replace(/[^0-9]/g, '');
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const numberString = parsePhoneNumberString(phoneNumber);
  const match = numberString.match(PhoneNumberRegex);
  if (match) {
    const [, intlCode, firstPart, secondPart, thirdPart] = match;
    const formattedNumber = intlCode
      ? `1-${firstPart}-${secondPart}-${thirdPart}`
      : `(${firstPart}) ${secondPart}-${thirdPart}`;

    return formattedNumber;
  }
  // Naive approach
  let formattedValue = numberString;
  if (formattedValue.length > 6) {
    formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
      3,
      6
    )}-${formattedValue.slice(6)}`;
  } else if (formattedValue.length > 3) {
    formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
      3
    )}`;
  }
  return formattedValue;
};

export const uploadImage = async (
  imageSubmissionData: UploadImageData
): Promise<void> => {
  const { media, s3AuthData, s3Key } = imageSubmissionData;
  const formData = new FormData();
  formData.append('Content-Type', media.type);

  const {
    data: { url, fields }
  } = s3AuthData;

  fields.key = s3Key;

  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  formData.append('file', media);

  await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const readFilePath = async (
  capacitorFile: Photo
): Promise<{ file: File | Blob; type: string } | null> => {
  // Here's an example of reading a file with a full file path. Use this to
  // read binary data (base64 encoded) from plugins that return File URIs, such as
  // the Camera.
  const { path, format } = capacitorFile;
  if (!path) return null;

  const contents = await Filesystem.readFile({ path });

  const url = `data:image/${format};base64,${contents.data}`;
  const res = await fetch(url);
  const file = await res.blob();

  return { file, type: format };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSignedLatitude = (lat: number, latRef: any): number =>
  lat && latRef === 'S' ? 0 - lat : lat;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSignedLongitude = (lng: number, lngRef: any): number =>
  lng && lngRef === 'W' ? 0 - lng : lng;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGPSDataFromFileIOS = (iOSFile: any): GPSData => {
  const {
    DestBearing,
    ImgDirection,
    Latitude,
    LatitudeRef,
    Longitude,
    LongitudeRef
  } = iOSFile?.exif?.GPS || {};

  return {
    bearing: DestBearing || ImgDirection,
    lat: getSignedLatitude(Latitude, LatitudeRef),
    lng: getSignedLongitude(Longitude, LongitudeRef)
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGPSDataFromFileAndroid = (androidFile: any): GPSData => {
  const {
    // @todo: these aren't working yet
    // GPSImgDirection,
    // GPSImgDirectionRef
    GPSLatitude,
    GPSLongitude
  } = androidFile?.exif || {};

  return {
    bearing: null,
    lat: GPSLatitude,
    lng: GPSLongitude
  };
};

export const formatFileUploadKey = (fileType: string): string => {
  const extension = fileType === 'jpeg' ? 'jpg' : fileType;
  const now = new Date();
  const rand = Math.ceil(Math.random() * 10000);
  const datePath = moment.utc().format('YYYY/M/DD');
  return `uploads/${datePath}/${now.getTime()}-${rand}.${extension}`;
};

export const isMobile = (): boolean => {
  return Capacitor.isNativePlatform();
};

export const isWildFireScheduled = (geoEvent: GeoEvent): boolean => {
  return !!(
    geoEvent.data.isPrescribed &&
    geoEvent.data.prescribedDateStart &&
    new Date(setNaiveISODateStringToUTC(geoEvent.data.prescribedDateStart)) >
      new Date()
  );
};

export const getEvacDescription = (data: {
  description: string;
  custom: boolean;
  hasEvacZones: boolean;
}): string | null => {
  const { description, custom, hasEvacZones } = data;
  if (hasEvacZones) {
    if (custom && description !== '<p></p>') return description;
    return null;
  }
  return description !== '<p></p>' ? description : '';
};

const isHTMLContent = (content: string): boolean => {
  return /<[a-z][\s\S]*>/i.test(content);
};

export const parseContentToHTML = (content: string): string => {
  if (isHTMLContent(content)) return content;
  const paragraphs = content.split('\n').map((paragraph) => {
    if (paragraph === '') return '';
    const pItems = paragraph.split(' ');
    const formattedPItems = pItems.map((pItem) => {
      if (pItem.startsWith('http://') || pItem.startsWith('https://')) {
        return `<a href="${pItem}">${pItem}</a>`;
      }
      return pItem;
    });
    return `<p>${formattedPItems.join(' ')}</p>`;
  });
  return paragraphs.join('');
};

export const getBounds = (
  lat: number,
  lng: number
): LngLatBounds | undefined => {
  const bounds = LngLatBounds.fromLngLat(new LngLat(lng, lat));
  if (bounds.isEmpty()) return undefined;
  return bounds;
};

export const getAbsoluteUrl = (url: string): string => {
  return !/^https?:\/\//i.test(url) ? `https://${url}` : url;
};

export const removeHTMLTags = (html: string): string => {
  return generateText(
    generateJSON(html, RICH_TEXT_EDITOR_EXTENSIONS),
    RICH_TEXT_EDITOR_EXTENSIONS,
    {
      blockSeparator: ' ',
      textSerializers: {
        hardBreak: () => ' '
      }
    }
  )
    .replace(/\s{2,}/g, ' ') // Replace 2 or more consecutive spaces with just 1
    .trim();
};

const getEvacZoneDefaultDescription = (
  evacZones: GeoEventEvacZoneStatus[]
): string => {
  const displayNames = evacZones
    .map((evacZone) => evacZone.evacZone.displayName.trim())
    .sort();
  return displayNames.join(', ');
};

export const getCustomEvacDescriptionFlags = (
  geoEvent?: GeoEvent
): {
  customOrders: boolean;
  customWarnings: boolean;
  customAdvisories: boolean;
} => {
  if (!geoEvent) {
    return {
      customOrders: false,
      customWarnings: false,
      customAdvisories: false
    };
  }

  const evacOrders = removeHTMLTags(
    geoEvent.data.evacuationOrders ?? ''
  ).trim();
  const evacWarnings = removeHTMLTags(
    geoEvent.data.evacuationWarnings ?? ''
  ).trim();
  const evacAdvisories = removeHTMLTags(
    geoEvent.data.evacuationAdvisories ?? ''
  ).trim();

  const defaultEvacOrders = getEvacZoneDefaultDescription(
    geoEvent.evacZoneStatuses.filter(
      (zoneStatus) => zoneStatus.status === 'orders'
    )
  );
  const defaultEvacWarnings = getEvacZoneDefaultDescription(
    geoEvent.evacZoneStatuses.filter(
      (zoneStatus) => zoneStatus.status === 'warnings'
    )
  );
  const defaultEvacAdvisories = getEvacZoneDefaultDescription(
    geoEvent.evacZoneStatuses.filter(
      (zoneStatus) => zoneStatus.status === 'advisories'
    )
  );

  return {
    customOrders: evacOrders !== defaultEvacOrders,
    customWarnings: evacWarnings !== defaultEvacWarnings,
    customAdvisories: evacAdvisories !== defaultEvacAdvisories
  };
};

// Max. 7 decimal places
export const parseCoordinate = (number: number): number =>
  parseFloat(number.toFixed(7));

export const getAppVersion = async (): Promise<string> => {
  if (!Capacitor.isNativePlatform()) {
    return 'web';
  }

  const cachedAppVersion = sessionStorage.getItem('appVersion');

  if (cachedAppVersion) {
    return cachedAppVersion;
  }

  const { version } = await App.getInfo();
  sessionStorage.setItem('appVersion', version);
  return version;
};

export const getRegionsDisplay = (regions: GeoEventRegion[]): string => {
  if (!regions.length) return '';

  if (regions.length === 1) {
    return `${regions[0].displayName}, ${regions[0].state}`;
  }

  let regionsList = regions
    .map((region) => region.displayName.replace(' County', ''))
    .join(', ');

  const replaceValue = regions.length > 2 ? ', & $1' : ' & $1';
  regionsList = regionsList.replace(/,\s([^,]+)$/, replaceValue);

  return `${regionsList} Counties`;
};

export const getAllNotificationRegions = (
  regions: NotificationRegion[],
  subscribedSettings: NotificationSetting[]
): NotificationSetting[] => {
  return regions.map((region) => ({
    region: {
      id: region.id,
      displayName: region.displayName,
      state: region.state,
      stateDisplayName: region.stateDisplayName,
      isReporterCoveredForFire: region.isReporterCoveredForFire
    },
    setting:
      subscribedSettings.find((setting) => setting.region.id === region.id)
        ?.setting || NotificationSettingTypes.off
  }));
};

export const getEvacZoneStyleFromGeoEvent = (
  geoEvent: GeoEvent
): RegionEvacZoneStyle =>
  geoEvent.regions[0]?.evacZoneStyle || EvacZoneStyles.default;

// Checks if the device is a phone considering the device orientation
export const isPhone = (): boolean =>
  Math.min(window.innerWidth, window.innerHeight) < 480;

export const findFuzzyMatch = (input: {
  query: string;
  candidates: string[];
  maxDistance?: number;
}): string | undefined => {
  const { query, candidates, maxDistance = 1 } = input;
  if (!query || !candidates.length) {
    return undefined;
  }
  const matches = [];
  for (const candidate of candidates) {
    if (candidate === query) {
      // exact match has been found, return
      return undefined;
    }
    const distance = levenshtein(query, candidate);
    if (distance <= maxDistance) {
      matches.push({ value: candidate, distance });
    }
  }
  return matches.sort((a, b) => a.distance - b.distance)[0]?.value;
};

export const includesFullWord = (text: string, keywords: string[]): boolean => {
  if (!keywords.length) return false;

  const pronounsAllowingApostrophe = new Set([
    'i',
    'me',
    'my',
    'mine',
    'myself',
    'we',
    'us',
    'our',
    'ours',
    'ourselves'
  ]);

  const buildRegexPart = (keyword: string): string => {
    const lowerKeyword = keyword.toLowerCase();

    if (lowerKeyword === '&') {
      return '(?<!\\w)&(?!\\w)';
    }
    // Match a keyword when:
    // 1. Not preceded by a word character
    // 2. Either enclosed in quotes/parentheses or standalone
    // 3. If standalone, not followed by a letter or disallowed punctuation
    const isPronoun = pronounsAllowingApostrophe.has(lowerKeyword);

    const apostropheSuffix = isPronoun ? `'(?:m|s|ve|re|ll)\\b` : '';

    const keywordPattern = isPronoun
      ? `(?:"${keyword}"|\\(${keyword}\\)|${keyword}(?:${apostropheSuffix}|(?![^\\s.!?:;"\\])])))`
      : `(?:"${keyword}"|\\(${keyword}\\)|${keyword}(?![^\\s.!?:;"\\])]))`;

    return `(?<!\\w)${keywordPattern}`;
  };

  // Filter out empty keywords and build regex
  const regexParts = keywords
    .map((keyword) => keyword.trim()) // Remove extra spaces from keywords
    .filter(Boolean)
    .map(buildRegexPart);

  if (!regexParts.length) return false;

  const regex = new RegExp(regexParts.join('|'), 'i');
  return regex.test(text);
};
