import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API, CacheAPI } from 'api';
import { GeoEvent, GeoEventEvacZoneStatus, Location } from 'shared/types';
import { useCacheState } from 'state';
import { AxiosResponse } from 'axios';
import useGeoEventQuery from './useGeoEventQuery';

const fetchActiveEvacZones = async (
  cacheBusterTs: number | null
): Promise<AxiosResponse<GeoEventEvacZoneStatus[]>> => {
  if (cacheBusterTs) {
    return CacheAPI.get<GeoEventEvacZoneStatus[]>(
      `evac_zones/statuses/?ts=${cacheBusterTs}`
    );
  }
  return API.get<GeoEventEvacZoneStatus[]>('evac_zones/statuses');
};

// we want to ensure that we are using the most up to date for statuses that can be returned from either
//    useActiveEvacZonesQuery or geoEvent.evacZoneStatuses.
//    If new/updated data exists in geoEvent.evacZoneStatuses and not in evacZonesQuery, then let the caller know
//    then we should invalidate the querycache for the useActiveEvacZonesQuery to ensure that the main map
//    view also reflects this when moving out of the selected incident state.
export const mergeEvacZones = ({
  geoEventStatuses,
  activeEvacZones
}: {
  geoEventStatuses: GeoEventEvacZoneStatus[];
  activeEvacZones: GeoEventEvacZoneStatus[];
}): [GeoEventEvacZoneStatus[], boolean] => {
  let shouldPerformCacheInvalidation = false;
  const allUids = Array.from(
    new Set([
      ...activeEvacZones.map((zone) => zone.evacZone.uidV2),
      ...geoEventStatuses.map((zone) => zone.evacZone.uidV2)
    ])
  );
  const combined = allUids.map((uid) => {
    const selectedGeoEventEvacZone = geoEventStatuses.find(
      (zone) => zone.evacZone.uidV2 === uid
    );
    const activeEvacZone = activeEvacZones.find(
      (zone) => zone.evacZone.uidV2 === uid
    );
    // if this is a new zone not returned in the evac_status fetch, we should perform cache invalidation
    if (selectedGeoEventEvacZone && !activeEvacZone) {
      shouldPerformCacheInvalidation = true;
    }
    //  or if the statuses don't match - assume evac_statuses is now out of date as well
    if (
      selectedGeoEventEvacZone &&
      activeEvacZone &&
      selectedGeoEventEvacZone.status !== activeEvacZone.status
    ) {
      shouldPerformCacheInvalidation = true;
    }
    return selectedGeoEventEvacZone || activeEvacZone;
  });

  const filtered = combined.filter(
    (zoneOrUndefined) => !!zoneOrUndefined
  ) as GeoEventEvacZoneStatus[];
  return [filtered, shouldPerformCacheInvalidation];
};

const useActiveEvacZonesQuery = (): GeoEventEvacZoneStatus[] => {
  const { cacheBusterTs } = useCacheState();
  const queryClient = useQueryClient();

  const { geoEvent: selectedGeoEvent } =
    useGeoEventQuery<GeoEvent | Location>();

  const { data, isLoading, isStale } = useQuery({
    queryKey: ['active-evacuation-zones', cacheBusterTs],
    queryFn: () => fetchActiveEvacZones(cacheBusterTs),
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 6 // 6 minutes - must be bigger than staleTime
  });

  const activeEvacZones: GeoEventEvacZoneStatus[] = useMemo(
    () => data?.data ?? [],
    [data?.data]
  );

  const [combinedZones, shouldPerformCacheInvalidation] = useMemo(() => {
    const geoEventStatuses =
      !!selectedGeoEvent && selectedGeoEvent.geoEventType === 'wildfire'
        ? selectedGeoEvent.evacZoneStatuses
        : [];
    return mergeEvacZones({ geoEventStatuses, activeEvacZones });
  }, [selectedGeoEvent, activeEvacZones]);

  // in the case when we've compared the values from geoEvent to a loading state
  // for evac/statuses, we don't want to invalidate the query
  if (shouldPerformCacheInvalidation && !isLoading && !isStale) {
    queryClient.invalidateQueries({
      queryKey: ['active-evacuation-zones']
    });
  }
  return combinedZones;
};

export default useActiveEvacZonesQuery;
