import { useEffect } from 'react';
import Map, { DEFAULT_VIEW_STATE } from 'components/Map';
import {
  includesFullWord,
  formatFileUploadKey,
  getAbsoluteUrl,
  getEvacDescription,
  getTimePass
} from 'shared/utils';
import { GeoEvent, GeoEventRegion, LatLng } from 'shared/types';
import { useMap } from 'react-map-gl/maplibre';
import { TFunction } from 'i18next';
import {
  DynamicMapCenterProps,
  FormValues,
  GeoCodeItem,
  GeoEventData,
  LocationPlaceResult,
  RegionOption,
  ReverseGeoCodeResponse
} from './types';
import { LinkTypes } from '../../../constants';
import { MapBoxAPI } from '../../../api';

const reverseGeoCodeCoords = async (
  lat: number,
  lng: number
): Promise<GeoCodeItem> => {
  const response = await MapBoxAPI.get<ReverseGeoCodeResponse>(
    `${lng},${lat}.json`,
    {
      params: {
        access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        types: 'place,address',
        language: 'en',
        limit: '1',
        country: 'us'
      }
    }
  );
  return response.data.features[0];
};

const getLocationPlace = async (
  lat: number,
  lng: number
): Promise<LocationPlaceResult> => {
  const geocodeFeature = await reverseGeoCodeCoords(lat, lng);

  const stateComponent = geocodeFeature.context.find((c) =>
    c.id.includes('region')
  )?.shortCode;

  const countyComponent = geocodeFeature.context.find((c) =>
    c.id.includes('district')
  )?.text;

  const county = countyComponent
    ?.toLowerCase()
    .replace('county', '')
    .trim()
    .replaceAll(' ', '_');

  let placeName = '';
  if (geocodeFeature.placeType[0] === 'address') {
    const [address, place] = geocodeFeature.placeName.split(',');
    if (address) placeName += address.trim();
    if (place) placeName += `, ${place.trim()}`;
    if (!placeName) placeName = geocodeFeature.text;
  } else {
    placeName = geocodeFeature.text;
  }

  return {
    county,
    state: stateComponent?.replace('US-', ''),
    placeName
  };
};

export const getMatchingRegions = async (
  lat: number,
  lng: number,
  regionOptions: RegionOption[]
): Promise<{ placeName: string; matchingRegions: RegionOption[] }> => {
  const { county, state, placeName } = await getLocationPlace(lat, lng);

  const matches = regionOptions.filter((region) => {
    const [regionCounty, regionStateUS] = region.name.split('-');
    if (state) {
      return regionCounty === county && regionStateUS.includes(state);
    }
    return regionCounty === county;
  });

  return { placeName, matchingRegions: matches };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRedundancyMessage = (errorResponse: any): string => {
  const { data: errorData } = errorResponse;

  if (errorData.length === 1) {
    const recentGeoEvent = errorData[0];
    const creator = recentGeoEvent.userCreated?.username || 'Someone';
    const timeSince = getTimePass(recentGeoEvent.dateCreated);

    return `${creator} just posted ${timeSince}: \n"${recentGeoEvent.name}"\n\nAre you sure you still want to post this incident?`;
  }

  return `${errorData.length} incidents have been created in the past few minutes for this county!\n\nAre you sure you still want to post this incident?`;
};

export const hasEvacInfoChanged = (
  prev: string | null,
  next: string
): boolean => {
  const prevValue = (prev || '').trim();
  const nextValue = next.trim();
  return prevValue !== nextValue;
};

export const parseFormValues = (
  values: FormValues,
  hasEvacZones: boolean
): GeoEventData => {
  const geoEventData: GeoEventData = {
    name: values.name,
    address: values.address,
    regions: values.regions,
    isActive: values.status === 'active',
    notificationType: values.notificationType,
    geoEventType: 'wildfire',
    lat: values.lat,
    lng: values.lng,
    reporterManaged: true,
    data: {
      geoEventType: 'wildfire',
      acreage: values.acreage,
      containment: values.containment,
      isFps: values.isFps,
      isPrescribed: values.isPrescribed,
      evacuationNotes: getEvacDescription({
        description: values.evacuationNotes,
        custom: false,
        hasEvacZones: false
      }),
      evacuationOrders: getEvacDescription({
        description: values.evacuationOrders,
        custom: values.customOrders,
        hasEvacZones
      }),
      evacuationWarnings: getEvacDescription({
        description: values.evacuationWarnings,
        custom: values.customWarnings,
        hasEvacZones
      }),
      evacuationAdvisories: getEvacDescription({
        description: values.evacuationAdvisories,
        custom: values.customAdvisories,
        hasEvacZones
      }),
      links: values.links.map((link) => {
        if (link.linkType === LinkTypes.website) {
          return { ...link, value: getAbsoluteUrl(link.value) };
        }
        return link;
      })
    },
    evacZoneStatuses: values.evacZoneStatuses.map((eZS) => ({
      status: eZS.status,
      evacZone: { id: eZS.evacZone.id }
    }))
  };
  if (values.prescribedDateStartLocal) {
    geoEventData.data.prescribedDateStartLocal =
      values.prescribedDateStartLocal.toISOString();
  }
  if (values.reportMessage || values.embedUrl || values.media) {
    geoEventData.initialReport = {};
  }
  if (values.reportMessage) {
    geoEventData.initialReport!.message = values.reportMessage;
  }
  if (values.embedUrl) {
    geoEventData.initialReport!.embedUrl = values.embedUrl;
  }
  if (values.media) {
    const mediaUrl = { url: formatFileUploadKey(values.fileType) };
    geoEventData.initialReport!.media = [
      {
        lat: values.mediaLat && values.mediaLat !== '' ? values.mediaLat : null,
        lng: values.mediaLng && values.mediaLng !== '' ? values.mediaLng : null,
        az: values.mediaAz ?? null,
        ...mediaUrl
      }
    ];
  }
  return geoEventData;
};

export const hasRegionsChanged = (
  prevRegions: GeoEventRegion[],
  newRegions: GeoEventRegion[]
): boolean => {
  return (
    prevRegions.length !== newRegions.length ||
    prevRegions.some(
      (prevRegion, index) => prevRegion.id !== newRegions[index].id
    )
  );
};

export const getDefaultMapCenter = (data: {
  geoEvent?: GeoEvent;
  searchParams?: URLSearchParams;
  center?: { lat: number; lng: number };
}): LatLng => {
  const { geoEvent, searchParams, center } = data;

  if (geoEvent?.id) {
    return { lat: geoEvent.lat, lng: geoEvent.lng };
  }

  const latParam = parseFloat(searchParams?.get('lat') ?? '0');
  const lngParam = parseFloat(searchParams?.get('lng') ?? '0');

  if (latParam && lngParam) {
    return { lat: latParam, lng: lngParam };
  }

  if (center && center.lat && center.lng) {
    return { lat: center.lat, lng: center.lng };
  }

  return Map.DefaultConfig.center;
};

export const DynamicMapCenter = (props: DynamicMapCenterProps): null => {
  const { lat, lng } = props;
  const { current: map } = useMap();

  useEffect(() => {
    if (!lat || !lng || isNaN(lat) || isNaN(lng)) {
      return;
    }

    map?.easeTo({
      center: [lng, lat],
      zoom: DEFAULT_VIEW_STATE.minZoom
    });
  }, [lat, lng, map]);

  return null;
};

export const validateIncidentName = (
  incidentName: string,
  t: TFunction
): string | null => {
  if (!incidentName) return null;

  const validKeywords = ['fire', 'prescribed', 'burn'];

  if (!includesFullWord(incidentName, validKeywords)) {
    return t('createEditGeoEvent.inputs.name.warning');
  }

  return null;
};

export const validateIncidentAddress = (
  address: string,
  t: TFunction
): string | null => {
  if (!address) return null;

  const validKeywords = [
    'block',
    'and',
    '&',
    'cross',
    'at',
    'near',
    'off',
    'of'
  ];

  if (!includesFullWord(address, validKeywords)) {
    return t('createEditGeoEvent.inputs.address.warning');
  }

  return null;
};

export const validatePrescribedIncident = (
  incidentName: string,
  isPrescribed: boolean,
  t: TFunction,
  isDescription = false
): string | null => {
  if (!incidentName) return null;

  if (!isPrescribed && includesFullWord(incidentName, ['prescribed'])) {
    if (isDescription) {
      return t('createEditGeoEvent.inputs.reportMessage.warning');
    }
    return t('createEditGeoEvent.prescribedBurnWarning');
  }

  return null;
};
